/**
 * @author Jeoff Morris <jeoffmorris@icloud.com>
 */
import React from 'react';
import { DetailsForm } from "./components/forms/details/DetailsForm";

import './App.css';

function App() {
  return (
    <div className="App">
      <DetailsForm />
    </div>
  );
}

export default App;
