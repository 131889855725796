/**
 * @author Jeoff Morris <jeoffmorris@icloud.com>
 */

import React from 'react'

export const ErrorDiv = (props: any, key: any) => {
  return (
    <div className="error" key={key}>{props.error}{props.modalMessage}</div>
  )
}
