/**
 * @author Jeoff Morris <jeoffmorris@icloud.com>
 * @todo if I have time animate the sections ease-in-out
 */
import React, { useEffect, useState } from 'react'
import axios from "axios";
import FormValidation from "../../../utils/FormValidation";
import { Headers } from "./Headers";
import { ErrorDiv } from "./ErrorDiv";

import './DetailsForm.css'

/**
 * @function DetailsForm details form 
 * @returns {JSX.Element}
 */
export const DetailsForm = () => {
const [expanded, setExpanded] = useState('details')
const [fname, setfname] = useState("")
const [lname, setlname] = useState("")
const [email, setEmail] = useState("")
const [tel, setTel] = useState("")
const [gender, setGender] = useState("")
const [day, setDay] = useState("")
const [month, setMonth] = useState("")
const [year, setYear] = useState("")
const [comments, setComments] = useState("")
const [fnameTitle, setfnameTitle] = useState("First Name(s)")
const [lnameTitle, setlnameTitle] = useState("Surname")
const [emailTitle, setEmailTitle] = useState("Email Address")
const [telTitle, setTelTitle] = useState("Telephone Number")
const [genderTitle, setGenderTitle] = useState("Gender")
const [dayTitle, setDayTitle] = useState("Day")
const [monthTitle, setMonthTitle] = useState("Month")
const [yearTitle, setYearTitle] = useState("Year")
const [headerTitle, setHeaderTitle] = useState("")
const [formErrors, setFormErrors] = useState([<ErrorDiv key="0"/>])

/**
 * This useEffect checks the active element and puts a red border around those that have a form validation error 
 */
useEffect(() => {

  // Check focus of form inputs
  // check elements exist
  if (document.activeElement != null && document.activeElement.tagName != null) {
    // for validation errors throw red border to suggest error
    /** @todo make a generic function to avoid code repetition */
    switch (document.activeElement.getAttribute('name')) {
      case 'fname':
        if (document.activeElement.getAttribute('name') != null) {
          document.getElementsByName('fname')[0].addEventListener('blur', ev => {
            let formError = new FormValidation(fname);
            formError.validFirstName()
            if (formError.validFirstName() != null && fname !== '') {
              document.getElementsByName('fname')[0].style.border = "1px solid red" 
              setfnameTitle(formError.error)
            } else if (fname === '') {
              document.getElementsByName('fname')[0].style.border = "1px solid red" 
              setfnameTitle('First Name(s) are required')
            } else {
              document.getElementsByName('fname')[0].style.border = "none"
            } 
          }, false)
          document.getElementsByName('fname')[0].addEventListener('focus', ev => {
            document.getElementsByName('fname')[0].style.border = "none"
            setfnameTitle("First Name(s)")
          }, false)
        }
        break
        case 'lname':
          if (document.activeElement.getAttribute('name') != null) {
            document.getElementsByName('lname')[0].addEventListener('blur', ev => {
              let formError = new FormValidation(fname);
              formError.validLastName()
              if (formError.validLastName() != null && fname !== '') {
                document.getElementsByName('lname')[0].style.border = "1px solid red" 
                setlnameTitle(formError.error)
              } else if (fname === '') {
                document.getElementsByName('lname')[0].style.border = "1px solid red" 
                setlnameTitle('Last Name(s) are required')
              } else {
                document.getElementsByName('lname')[0].style.border = "none"
              } 
            }, false)
            document.getElementsByName('lname')[0].addEventListener('focus', ev => {
              document.getElementsByName('lname')[0].style.border = "none"
              setfnameTitle("Last Name(s)")
            }, false)
          }
          break
        case 'email':
        if (document.activeElement.getAttribute('name') != null) {
            document.getElementsByName('email')[0].addEventListener('blur', ev => {
              let formError = new FormValidation(fname);
              formError.validEmail()
              if (formError.validEmail() != null && fname !== '') {
                document.getElementsByName('email')[0].style.border = "1px solid red" 
                setEmailTitle(formError.error)
              } else if (fname === '') {
                document.getElementsByName('email')[0].style.border = "1px solid red" 
                setEmailTitle('Email address is required')
              } else {
                document.getElementsByName('email')[0].style.border = "none"
              } 
            }, false)
            document.getElementsByName('email')[0].addEventListener('focus', ev => {
              document.getElementsByName('email')[0].style.border = "none"
              setfnameTitle("Last Name(s)")
            }, false)
          }
          break
        case 'tel':
          if (document.activeElement.getAttribute('name') != null) {
            document.getElementsByName('tel')[0].addEventListener('blur', ev => {
              let formError = new FormValidation(fname);
              formError.validPhoneNumber()
              if (formError.validPhoneNumber() != null && fname !== '') {
                document.getElementsByName('tel')[0].style.border = "1px solid red" 
                setTelTitle(formError.error)
              } else if (fname === '') {
                document.getElementsByName('tel')[0].style.border = "1px solid red" 
                setTelTitle('A telephone number is required')
              } else {
                document.getElementsByName('tel')[0].style.border = "none"
              } 
            }, false)
            document.getElementsByName('tel')[0].addEventListener('focus', ev => {
              document.getElementsByName('tel')[0].style.border = "none"
              setfnameTitle("Telephone number")
            }, false)
          }
          break
        case 'day':
          if (document.activeElement.getAttribute('name') != null) {
            document.getElementsByName('day')[0].addEventListener('blur', ev => {
              if ((document.getElementsByName('day')[0] as HTMLInputElement).value === '') document.getElementsByName('day')[0].style.border = "1px solid red"
              setDayTitle("The day is required")
            })
          }
          break
        case 'month':
          if (document.activeElement.getAttribute('name') != null) {
            document.getElementsByName('month')[0].addEventListener('blur', ev => {
              if ((document.getElementsByName('month')[0] as HTMLInputElement).value === '') document.getElementsByName('month')[0].style.border = "1px solid red"
              setYearTitle("The month is required")
            })
          }
          break
        case 'year':
          if (document.activeElement.getAttribute('name') != null) {
            document.getElementsByName('year')[0].addEventListener('blur', ev => {
              if ((document.getElementsByName('year')[0] as HTMLInputElement).value === '') document.getElementsByName('year')[0].style.border = "1px solid red"
              setYearTitle("The year is required")
            })
          }
          break          
      default:
          break
    }
    let genderElement = document.getElementById('gender');      
    if (genderElement !== null) {
      genderElement.addEventListener('blur', ev => {
        let genderFormError = new FormValidation(gender);
        if (genderFormError.error !== '') {
          if (genderElement) genderElement.style.border = "1px solid red"
        } else if (genderElement) {
          genderElement.style.border = "none"
        }
      })
    }
  }
}) 
  
/**
 * @function expand sets expanded header
 * 
 * @param header the selected header name
 * @returns {void}
 */
  const expand = (header: string) => {
    switch (header) {
      case 'details': setExpanded('details')
        break
      case 'more': setExpanded('more')
        break
      case 'final': setExpanded('final')
        break
      default:
        setExpanded('details')
    }
  }

  /**
   * @function showModal removes the modal
   * 
   * @returns {void}
   */
   const showModal = () => { 
    const modal = document.getElementById('modal');
    if (modal != null)
      modal.style.display = 'grid'
  }

  /**
   * @function removeModal removes the modal
   * 
   * @returns {void}
   */
  const removeModalErrors = (ev: any) => { 
    ev.preventDefault()
    const modal = document.getElementById('modal');
    if (modal != null)
      modal.style.display = 'none'
  }

  /**
   * @function submitForm submitsForm
   * 
   * @returns {boolean|Array<string>} returns success or array of errors
   */
  const submitForm = (e: any) => {
    e.preventDefault()
    const fnameValid = new FormValidation(fname)
    fnameValid.validFirstName()
    const lnameValid = new FormValidation(lname)
    lnameValid.validLastName()
    const emailValid = new FormValidation(email)
    emailValid.validEmail()
    const telValid = new FormValidation(tel)
    telValid.validPhoneNumber()
    const genderValid = new FormValidation(gender)
    genderValid.validGender()
    const dateValid = new FormValidation(`${day}/${month}/${year}`)
    dateValid.validDate()

    let errors: Array<string> = []
    if (fnameValid.error.length) errors.push(fnameValid.error)
    if (lnameValid.error.length) errors.push(lnameValid.error)
    if (emailValid.error.length) errors.push(emailValid.error)
    if (telValid.error.length) errors.push(telValid.error)
    if (genderValid.error.length) errors.push(genderValid.error)
    if (dateValid.error.length) errors.push(dateValid.error)

    let errorDivs: JSX.Element[] = []
    if (errors.length) {
      errors.forEach((error, index) => {
        errorDivs.push(<ErrorDiv error={error} key={index}/>)
      })

      setHeaderTitle('Form Errors')
      showModal()
      
      setFormErrors(errorDivs)
    } else {
      axios.post(process.env.REACT_APP_PUBLIC_URL + ':' + process.env.REACT_APP_SERVER_PORT + '/form', { fname: fname, lname: lname, email: email, tel: tel, gender: gender, day: day, month: month, year: year, comments: comments }).catch(err => {
        if (err) {
          setHeaderTitle('Error Messages')
          showModal() 
          
          const modalBody = document.getElementById('modal-container');
          const dataDiv = document.createElement('div');
          const textNode = document.createTextNode(err)

          dataDiv.setAttribute('id', 'errors')
          dataDiv.setAttribute('class', 'errors')
          dataDiv?.appendChild(textNode)
          modalBody?.appendChild(textNode)
        }
      }).then((res) => {
        if (res) {
          setHeaderTitle('Success')
          showModal() 
          
          const modalBody = document.getElementById('modal-container');
          const dataDiv = document.createElement('div');
          const textNode = document.createTextNode(res.data)
          dataDiv.setAttribute('id', 'success')
          dataDiv.setAttribute('class', 'success')
          dataDiv?.appendChild(textNode)
          modalBody?.appendChild(textNode)
          setTimeout(() => {
            window.location.href = '/'
          }, 5000);
        }
      })
    }
  }

  return (
    <div className="details-form">
      <form onSubmit={submitForm} id="form" autoComplete="on">
        <div className="form-section">
          <div onClick={() => expand('details')}>
          <Headers  headerTitle="Step 1: Your details" /></div>
          {expanded === "details" ? <div>
          <div className="form-group">
            <div><label htmlFor="fname">First Name</label></div>
            <div><label htmlFor="lname">Surname</label></div>
          </div>
          <div className="form-group">
              <div><input required type="text" tabIndex={1} pattern="^((([\w]+)([-]|[ ]{1})?([\w]+)?){3})$" title={fnameTitle} name="fname" value={fname} onChange={e => setfname(e.target.value)} autoComplete="given-name" /></div>
            <div><input required type="text" tabIndex={2} pattern="^((([\w]+)([-]|[ ]{1})?([\w]+)?){1})$" title={lnameTitle} name="lname" value={lname}  onChange={e => setlname(e.target.value)}  autoComplete="family-name" /></div>
          </div>
          <div className="form-group">
            <div><label htmlFor="email">Email Address:</label></div>
          </div>
          <div className="form-group">
            <div><input required type="email" name="email" title={emailTitle}  value={email} onChange={e => setEmail(e.target.value)} tabIndex={3} autoComplete="email"/></div>
          </div>
          <div className="next">
            <button name="detailsButton" className="button" onClick={() => expand('more')}  tabIndex={4}>Next {'>'} </button> 
          </div>
          </div> : ''}
        </div>
        <div className="form-section">
        <div  onClick={() => expand('more') }>
            <Headers headerTitle="Step 2: More comments" />
          </div>
        {expanded === "more" ? <div className="more">
          <div>
            <div className="form-group">
              <div><label htmlFor="tel">Telephone Number</label></div>
              <div><label htmlFor="gender">Gender</label></div>
            </div>
            <div className="form-group">
                <div><input required type="tel" name="tel" title={telTitle} tabIndex={1} onChange={e => setTel(e.target.value)} value={tel}  autoComplete="tel"/></div>
                <div>
                  <select id="gender" title={genderTitle} onChange={e => setGender(e.target.value)} required name="gender"  autoComplete="gender" tabIndex={2}>
                    <option>Select Gender</option>
                    <option value="male">Male</option>
                    <option value="female">Female</option>
                    <option value="other">Other</option>
                  </select>
                </div>
            </div>
            <div className="form-group">
              <div><label htmlFor="date">Date of Birth</label></div>
            </div>
            <div className="date-form-group">
                <input required className="date" type="number" name="day" min={1} max={31} tabIndex={3} title={dayTitle} value={day} onChange={e => setDay(e.target.value)}/><input required className="number" type="number" min={1} max={12} name="month" tabIndex={4} title={monthTitle} value={month} onChange={e => setMonth(e.target.value)}/><input required min={1926} max={2022} className="year" type="text" name="year" title={yearTitle} tabIndex={5} size={2} maxLength={4} value={year} onChange={e => setYear(e.target.value)}/>
              </div>
              <div className="next">
            <button className="button" onClick={() => expand('final')} tabIndex={6} name="moreButton">Next {'>'}</button> 
          </div>
          </div>
          </div> : ''}
        </div>
        <div className="form-section">
          <div  onClick={() => { expand('final') }}>
            <Headers headerTitle="Step 3: Final comments" />
          </div>
          {expanded === "final" ? <div>
            <div><span className="comments">Comments</span></div>
            <div className="final-group"><div><textarea cols={35} rows={7} tabIndex={1} name="comments" title="Comments" value={comments} onChange={e => setComments(e.target.value)}></textarea></div><div><button className="button submit" onClick={(e) => submitForm(e)} tabIndex={2} name="submitButton" value="">Next {'>'}</button> </div></div>
        </div> : ''}</div>
      </form>
      <div className="modal" id="modal" onClick={e => removeModalErrors(e)}>
        <div className="modal-container" id="modal-container" >
          <Headers  headerTitle={headerTitle} />
          <div id="errors" className="errors">
            {formErrors}
          </div>
        </div>
      </div>
    </div>

  )
}
