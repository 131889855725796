/**
 * @author Jeoff Morris <jeoffmorris@icloud.com>
 */
import moment from 'moment';

/**
 * @class Class to validate data from forms
 */
export default class FormValidation {
  /** regex expressions */
  private emailTest =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  /** all letters with dashes, up to 3 words */
  private firstNameTest = /^((([\w]+)([-]|[ ]{1})?([\w]+)?){3})$/;

  /** all letters with dashes, up to 3 words */
  private lastNameTest = /^((([\w]+)([-]|[ ]{1})?([\w]+)?){3})$/;

  /**
   * United Kingdom phone number format regex:
   * accepts:
   * 01902123456 | 01902 123456 | (01902)123456 | (01902) 123456 | +441902123456 | +441902 123456 | +44 1902123456 | * +44 1902 123456 | 07807123456 | 07807 123456 | +447807123456 | +447807 123456 | +44 7807123456 | +44 7807 123456
   */
  private phoneNumberTest =
    /^((\+44\s?\d{4}|\(?\d{5}\)?)\s?\d{6})|((\+44\s?|0)7\d{3}\s?\d{6})$/;

  public error: string = '';

  /**
   * @param data
   */
  constructor(public data: string) {
    // trim all data of white space
    this.data = data.replace(/^\s+|\s+$/gm, '');
  }

  /**
   * @method invalidEmail Method used to verify an email
   *
   * @returns {boolean|string}
   */
  validEmail = () => {
    return this.emailTest.test(this.data) === true
      ? null
      : (this.error = 'the email entered is in an invalid format');
  };

  /**
   * @method invalidFirstName Method used to verify an name
   *
   * @returns {boolean|string}
   */
  validFirstName = () => {
    return this.firstNameTest.test(this.data) === true
      ? null
      : (this.error = 'the first name entered is not valid word');
  };

  /**
   * @method invalidLastName Method used to verify an name
   *
   * @returns {boolean|string}
   */
  validLastName = () => {
    return this.lastNameTest.test(this.data) === true
      ? null
      : (this.error = 'the surname entered is not valid word');
  };

  /**
   * @method invalidPhoneNumber Method used to verify a phone number
   *
   * @returns {boolean|string}
   */
  validPhoneNumber = () => {
    return this.phoneNumberTest.test(this.data) === true
      ? null
      : (this.error = 'a valid phone number must be entered');
  };

  /**
   * @method invalidGender Method used to verify gender, not required to send an error message as fetched from a select box
   *
   * @returns {boolean}
   */
  validGender = () => {
    return this.data === 'male' ||
      this.data === 'female' ||
      this.data === 'other'
      ? null
      : (this.error = 'a gender is required');
  };

  /**
   * @method invalidDate Method to verify date before db transactions
   *
   * @returns {boolean|string}
   */
  validDate = () => {
    return moment(this.data).isValid()
      ? null
      : (this.error = 'please enter a valid date such as 10/04/1981');
  };
}
