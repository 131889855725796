/**
 * @author Jeoff Morris <jeoffmorris@icloud.com>
 */
import React from 'react';

/**
 * @interface Props Interface for header title and click event
 * 
 * @member {string} headerTitle title of header
 */
interface Props {
  headerTitle: string;
} 

/**
 * @function Headers Function for details form headers elements
 * 
 * @returns {JSX.Element}
 */
export const Headers = (props: Props) => {
  return (
    <div className="header">
      <span className="header-title">{props.headerTitle}</span>
    </div>
  )
}
